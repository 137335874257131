import React from 'react';

export function DynamicPage({ title, img, link }) {
  return (
    <div>
        <h1>{title}</h1>
        <a href={link}>
            <img src={img}></img>
        </a>
    </div>
  )
}
