export const dynamicRoutes = {
    omnichannel: [
        { 
            id: "1", 
            title: "Терапевты (As1)", 
            img: "https://i.postimg.cc/pdqMLGBD/Aspirin-terapevty.png",
            link: "https://health-academy.bayer.ru/stati/mesto-preparatov-aczetilsaliczilovoj-kisloty-v-lechenii-orvi-i-grippa-u-vzroslyh-mnenie-ekspertov/?utm_source=medguru&utm_medium=other&utm_content=e-mail_GPs_resolution&utm_campaign=phd_bayer_2024_aspirin_pharmacists_doctors&invited=yes#simple"
        },

        { 
            id: "2", 
            title: "Терапевты (As2)", 
            img: "https://i.postimg.cc/yxqHMJP5/Snimok-ekrana-2024-12-03-v-16-29-08.png",
            link: "https://likexr.8thwall.app/aspirin/?utm_source=medguru&utm_medium=Mobile&utm_content=e-mail_AR_GPs_resolution&utm_campaign=phd_bayer_2024_aspirin_pharmacists_doctors&invited=yes#simple"
        },

        { 
            id: "3", 
            title: "Проктологи (Rl)", 
            img: "https://i.postimg.cc/63Tky4Q0/Relif-proktologi.png",
            link: "https://pro-gemorroy.info/post/analnyi-zud-daem-vernoe-napravlenie?utm_source=medguru&utm_medium=other&utm_content=email_procto_6&utm_campaign=phd_bayer_2024_relief&invited=yes"
        },

        { 
            id: "4", 
            title: "Хирурги (Rl)", 
            img: "https://i.postimg.cc/3J2P4T3P/Snimok-ekrana-2024-12-03-v-12-41-35.png",
            link: "https://pro-gemorroy.info/post/analnyi-zud-daem-vernoe-napravlenie?utm_source=medguru&utm_medium=other&utm_content=email_surg_6&utm_campaign=phd_bayer_2024_relief&invited=yes"
        },

        { 
            id: "5", 
            title: "Терапевты (Rl)", 
            img: "https://i.postimg.cc/vZDgrnJV/Terapevty.png",
            link: "https://health-academy.bayer.ru/stati/konservativnaya-terapiya-gemorroya-hitrosti-ot-proktologa-dlya-terapevtov/?utm_source=medguru-med&utm_medium=other&utm_content=e-mail_gp_6&utm_campaign=phd_bayer_2024_relief&invited=yes"
        },

        { 
            id: "6", 
            title: "Терапевты (Asb)", 
            img: "https://i.postimg.cc/pdqMLGBD/Aspirin-terapevty.png",
            link: "https://health-academy.bayer.ru/stati/mesto-preparatov-aczetilsaliczilovoj-kisloty-v-lechenii-orvi-i-grippa-u-vzroslyh-mnenie-ekspertov/?utm_source=medguru&utm_medium=other&utm_content=e-mail_GPs_resolution&utm_campaign=phd_bayer_2024_aspirin_pharmacists_doctors&invited=yes#simple"
        },
    ],
  };